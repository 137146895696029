.truncate-multiline {
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Number of lines to show */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wp-block-button.is-style-outline
  > .wp-block-button__link:not(.has-background):hover {
  background-color: var(--wp--preset--color--secondary);
  color: var(--wp--preset--color--background);
  border-color: var(--wp--preset--color--secondary);
}

.wp-block-table figcaption {
  font-size: var(--wp--preset--font-size--small);
  text-align: center;
}

/*
 * Link styles
 * https://github.com/WordPress/gutenberg/issues/42319
 */
.blog-content a {
  text-decoration-thickness: 0.0625em !important;
  text-underline-offset: 0.15em;
}

/*
 * Navigation Block
 * Reset the padding from List block
 * https://github.com/WordPress/gutenberg/issues/50486
 */
.wp-block-navigation ul {
  padding: unset;
}

/* Post styles*/

.blog-content .wp-block-heading {
  color: "#11181F";
  text-align: left;
}

.blog-content h1 {
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 28px;
  margin-top: 10px;
}

.blog-content h3 {
  font-size: 2rem;
  margin-bottom: 15px;
}

.blog-content h5 {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 20px;
  font-weight: 500;
}

.blog-content img {
  overflow: clip;
  max-height: 80vh;
  margin: 0 auto;
}

.blog-content p {
  text-align: left;
  margin-bottom: 30px;
  font-weight: 400;
  font-size: 18px;
}

.blog-content ul {
  padding-left: 1.75rem;
  text-align: left;
  list-style-type: disc;
  margin-bottom: 20px;
}

.blog-content a {
  text-decoration: underline;
}

.blog-content .wp-block-heading {
  margin-right: auto;
  font-weight: bold;
}

@media screen and (max-width: 700px) {
  .blog-content h1 {
    font-size: 2rem;
    width: 100%;
    line-height: normal;
  }
  .blog-content h3 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
}
