@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@font-face {
  font-family: "sk-modernist";
  src: url("assets/fonts/Sk-Modernist-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "sk-modernist";
  src: url("assets/fonts/Sk-Modernist-Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "sk-modernist-mono";
  src: url("assets/fonts/Sk-Modernist-Mono.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "sk-modernist";
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-primary-gradient {
  background: linear-gradient(223deg, #7475ff 5.53%, #6163f6 49.52%);
}
.bg-black-gradient {
  background: linear-gradient(210deg, #2f2f2f 79.13%, #3b3b3b 91.81%);
}

.bg-dark-gradient {
  background: linear-gradient(39deg, #1e1e1e 53.95%, #4a4a4a 101.88%);
}
.cc-cp-foot-byline {
  opacity: 0;
}

/* For Debugging Elements */
/* * {
    background-color:rgba(0, 0, 0, 0.1)!important
}  */

/* * * * {
    background-color:green!important
}
* * * * {
    background-color:blue!important 
}
* * * * * {
    background-color:purple!important
}
* * * * * * {
    background-color:yellow!important
}
* * * * * * *{
    background-color:orange!important
}
* * * * * * * *{
    background-color:red!important
}  */
